// Normalize Styles
@import '../../node_modules/normalize.css/normalize.css';


blueColor = #4c86c4;
accentColor = #1ab394;
yellowColor = #e9b70e;

.container-fluid {
  max-width: 78em
}
.clear {
  &:after {
    display: table;
    clear: both;
    content: '';
  }
}
body {
  font-family: 'Ubuntu', 'Helvetica', sans-serif;
  font-size: 16px;
  overflow-x: hidden;
  color: #21221D;
}
h2 {
  color: #5a5a5a;
  display: inline-block;
  margin-top: 2em;
  font-size: 2.3em;



}

.progress-bar{
  position: relative;
}
span.p-bar {
  background-color: #1ab394; //#e667c0;
  opacity: 0.8;
  // height:3px;
  top: 0;
  bottom:0;
  left:0;
  width:0;
  // width: 25%;

  position:absolute;
  z-index:1;

  border-radius:0 0 2px 2px;

  -webkit-transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  transition: width 0.5s, height 0.5s;
}

::-webkit-input-placeholder {color:blueColor;}
::-moz-placeholder          {color:blueColor;}/* Firefox 19+ */
:-moz-placeholder           {color:blueColor;}/* Firefox 18- */
:-ms-input-placeholder      {color:blueColor;}

input:focus, input:active, button:active, button:focus {
 outline: none;
}
a, a:focus, a:hover, a:visited, a:active {
  text-decoration: none;
  outline: none;
}

section h3, header h3 {
  font-size: 1.75em;
}
.cta_wrap {
  text-align: center
}
.cta {
  border: none;
  background: accentColor;
  color: #fff;
  border-radius: 0.25em;
  padding: 0.4em 0;
  position: relative;
  overflow: hidden;
  font-size: 1.4em;
  //border: .1em solid transparent;
  box-shadow: 0 0 2px rgba(0,0,0,.3);
  &:after {
    content: ''
    background: #fff
    width: 0;
    height: 0;
    border-radius: 3em;
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%);
    z-index: 0;
    transition: all .3s
  }
  &:hover:after {
    width: 115%;
    height: 115%
  }
  span {
    position: relative;
    z-index: 1;
    transition: color .3s;
    cursor: pointer
  }
  &:hover span {
    color: accentColor
  }
  &:hover {
    //border-color: accentColor
  }
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform:translate(-50%, -50%);
}

.back_form {
  width: 80%;
  height: 20em;
  background: #fff;
  -webkit-box-shadow: 2px 2px 20px rgba(0,0,0,0.3);
  box-shadow: 2px 2px 20px rgba(0,0,0,0.3);
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 5em;
  border-radius: 6px;
  transform: translateX(-50%);
}
header {
  height: 100vh;
  min-height: 700px;
  width: 100%;
  position: relative;
  padding: 2em 3.5em;
  background: url(../images/new/bg_main.jpg);
  background-size: cover;
  z-index: 3;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.4);
    z-index: -1;
  }
  .logo {
    float: left
    width: 15em
    cursor: pointer
    z-index: 2
    position: relative
    img {
      max-width: 100%
    }
  }
  .phone {
    float: right;
    color: #fff;
    font-size: 1.8em;
    z-index: 2;
    position: relative;
    padding: 0 .1em;
    margin-right: .6em;
    text-align: right;
    text-decoration: none;
    &:hover{
      color: white;
      text-decoration: none;
      cursor: pointer;
    }
    .small {
      display: block;
      font-size: 0.6em;
      margin-top: -0.1em;
    }
  }

  .heading_wrap {
    width: 62.5em;
    margin: auto;
    padding: 2em;
    border-radius: 0.4em;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
    .heading {
      float: left;
      width: 60%;
      p {
        font-size: 1.4em;
        line-height: initial;
        padding-left: 1.5em;
        position: relative;
        margin-bottom: 1.1em;
        &:before {
          content: '';
          position: absolute;
          left: -0.4em;
          width: 1.5em;
          height: 1.5em;
          top: 0;
          background: url(../images/new/checked_green.png);
          background-size: contain;
        }
      }
    }
      h1 {
        font-size: 3em;
        text-transform: uppercase;
        margin-top: 0;
        span {
          text-transform: none;
          font-size: 0.9em;
          display: block;
        }
      }
  .form_wrap {
    float: left;
    width: 40%;
    text-align: center;
    form {
      width: 80%;
      margin: 2.2em auto 0;
    }
    input {
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 1.0769em 0 2.5625em;
      height: 3.7692em;
      border: none;
      -moz-border-radius: 0.3846em;
      border-radius: 0.3846em;
      color: #5a5a5a;
      font-size: 0.8125em;

    }


    input[type="file"] {
          width: .01em
          height: .01em
          opacity: 0
          overflow: hidden
          z-index: -1
          position: relative
          font-weight: lighter
        }
    input.name {
      margin-bottom: 1em;
    }
    button.cta {
      width: 100%
    }
    .file_label {
      width: 100%;
      height: 3.0625em;
      border-radius: 0.3125em;
      border: 1px solid;
      margin: 1em 0;
      cursor: pointer;
      transition: color, background .3s;
      overflow: hidden
      span {
        display: inline-block;
        line-height: 3.0625em;
      }
      &:hover {
        background: #fff;
        color: #1ab394;
        border: none;
      }
    }
  }
  .region {
    display: inline-block;
    position: relative;
    top: 1em;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -2.5em;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
      width: 8em;
      height: 2em;
      background: url(../images/wave_green.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

}

.description {
  position: relative;
  overflow: hidden;
  padding-bottom: 6em;
  //background: url(../images/bg_owner.jpg) center / cover no-repeat
  background-image: url(../images/bg_achievements.jpg);
  background-size: 50em;
  text-align: center;

  h2 {
    color: #5a5a5a;
    display: inline-block;
    margin: 2em auto 2em;
    font-size: 2.3em;
    position: relative
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -2.5em;
      transform: translate(-50%);
      width: 5.6em;
      height: 2em;
      background: url(../images/wave.png);
      background-size: contain;
      background-repeat: no-repeat;
    }


  }
  p {
    font-size: 1.4em;
    line-height: 1.4em;
    margin-bottom: 1.6em;
    font-weight: lighter
    text-align: left;
    padding-left: 4em;
    margin-top: 2em;
    background-repeat: no-repeat
    background-size: 2.6em;
    background-position: left 50%;
    &.first {
      margin-top: 2em;
      background-image: url(../images/new/need.png);
    }
    &.second {
      background-image: url(../images/new/offer.png);
    }
    &.third {
      background-image: url(../images/new/require.png);
    }
    &.fourth {
      background-image: url(../images/new/deal.png);
    }
    span {
      font-size: 1.6em;
      display: block;
      font-weight: normal;
      color: #5a5a5a;
    }
  }

}
section.advantages {
  background: url(../images/new/topol.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 2;
  padding: 3em 0;
  margin: 0;
  color: #fff;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.6);
    left: 0;
    right: 0;
    z-index: -1;
  }
  h2 {
    text-align: center;
    color: #fff;
    display: block;
    margin: 0 auto 3em;
    position: relative
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -2.5em;
      transform: translate(-50%);
      width: 5.6em;
      height: 2em;
      background: url(../images/wave.png);
      background-size: contain;
      background-repeat: no-repeat;
    }

  }
  .item_wrap {
    margin-top: 5em;
  }
  .item {
    float: left;
    text-align: center;
    span {
      font-size: 1.3em;
      text-transform: uppercase;
    }
      img {
        display: inline-block;
        background: #fff;
        border-radius: 50%;
        padding: 0.8em;
        width: 5.4em;
        border: 1px solid #d53b46;
      }
      p {
        margin-top: 1em;
      }
  }
  .third {
    width: 33.33%;
  }
  .half {
    width: 50%;
    &:first-child {
      padding-left: 5em
    }
    &:last-child {
      padding-right: 5em;
    }
  }
  .img_wrap {
    display: inline-block;
    position: relative;
    z-index: 1;
    &:before {
      position: absolute;
      left: 49.5%;
      top: 49.6%;
      transform: translate(-50%, -50%);
      width: 6em;
      height: 6em;
      background: #fff;
      z-index: -1;
      content: '';
      border-radius: 50%;
    }
  }





}
section.achievements {
  padding: 6em 0;
  position: relative;
  background: url(../images/new/map.jpg);
  background-size: cover;
  z-index: 3;

  h2 {
    display: block;
    margin: 0 auto 2em;
    text-align: center;
    color: #222;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -2.5em;
      transform: translate(-50%);
      width: 5.6em;
      height: 2em;
      background: url(../images/wave_green.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  p {
    text-align: center;
    position: relative;
    font-size: 1.4em;

    span {
      font-size: 2.8em;
      top: 0.2em;
      font-weight: normal;
      display: block;
      position: relative;
      color: #5a5a5a;
    }

  }
  .second {
    margin-top: 1em
    p  span {
    font-size: 2.8em
  }
  }
  .wrapper {
    padding: 2em;
    z-index: 3;
    position: relative;

  }
  .cta_wrap {
    margin-top: 4em
  }
  .cta {
    display: inline-block
    position: relative
    z-index: 1;
    padding: .4em 1.2em;
    cursor: pointer;
    &:after {
      z-index: -1
    }
    &:hover {
      color: #1ab394;
    }
  }


}

section.story {
  padding: 0 0 3em;
  position: relative;
  background: url(../images/bg_blur2.jpg);
  background-size: cover;
  background-position: center;
  z-index: 1;
  color: #fff;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.7);
    left: 0;
    right: 0;
    z-index: -1;
  }


h2 {
  display: block;
  text-align: center;
  color: #fff;
  margin-bottom: 1em;
}
  h3 {
    text-align: left;
    padding: 1em 0 0.2em;
    margin: 0;
    position: relative
    font-weight: lighter
    font-size: 2em

    span {
      font-weight: normal
    }
  }
  h4 {
    font-size: 1.8em;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 2.8em;
      bottom: -2.5em;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
      width: 5.6em;
      height: 2em;
      background: url(../images/wave_green.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
    .wrapper {
      width: 80%
      margin: auto;
      padding-bottom: 2em
      .photo {
        width: 40%
        float: left
        .img-wrap {
          width: 80%;
          margin-left: 2em;
          margin-top: 2em;
          position: relative
          z-index: 1
          //&:after {
          //  content: '';
          //  position: absolute;
          //  width: 100%;
          //  top: 0;
          //  bottom: 1em;
          //  background: #e9b70e;
          //  left: -.5em;
          //  top: -.5em;
          //  z-index: -1;
          //}
        }
        img {
          max-width: 100%;
          border-radius: .4em;
          border: 0.2em solid #fff;

        }
      }
      .text {
        width: 60%;
        float: left;
        //background: url(../images/quote.png);
        //background-repeat: no-repeat;
        //background-size: 3.5em;
        //background-position: 0px 9.7em;
        p {
          box-sizing: border-box;
          padding: 3.2em 2em 0em 0;
          font-size: 1.2em;
          font-weight: lighter;
        }
      }

    }


  .descr_wrap {
    margin-top: 5em;
    h4 {
      font-style: italic
      font-weight: normal
      font-size: 1.4em
    }
    p:first-of-type {
      margin-top: 2.2em
    }
  }
  .more {
    font-size: 1.3em;
    text-decoration: underline;
    color: #1ab394;
  }
  .video_head {
    position: relative
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -2.5em;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
      width: 5.6em;
      height: 2em;
      background: url(../images/wave_green.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
    .videowrap {
      position: relative;
      margin-top: 5em;
      padding-bottom: 29.7em;
      border: 0.2em solid #ccc;
      border-radius: .2em;
      width: 53.1em;
      margin: 5em auto;
    }
.videowrap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  position: absolute;
}
}

section.owner {
  padding: 1em 0;
  background-image: url(../images/bg_achievements.jpg);
  background-size: 50em;

  h2 {
    display: block;
    text-align: center;

  }
  .heading {
    text-align: center;
    font-size: 1.4em;
    margin: 1em auto 2em;
    padding: .5em 1.2em;
    display: inline-block;
    color: #5a5a5a;
  }
  .img_wrap {
    text-align: center
    position: relative;

  }
  .descr_head {
    padding-bottom: 3em;
    background: url(../images/wave.png);
    background-size: 10.5em;
    background-repeat: no-repeat;
    background-position: 0% 90%;
    font-size: 1.3em;
  }
    .anetta_wrap{
      position: relative
      &:after {
        content: '';
        position: absolute;
        bottom: -6.4em
        height: 6.5em;
        width: .3em;
        background: #93db2d;
        left: 47.1%;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
      }
    }


  .anetta {
    border: 0.3em solid #93db2d;
    border-radius: .2em;
    width: 15em;
    margin-left: -1.4em;
}
  .descr {
    padding: 1em;
    background: #eceeef;
  }
    .profi {
      display: block;
      margin: 6em auto 1em;
      width: 50%;
    }
    .profi + a {
      text-decoration: underline;
      font-size: 1.3em;
      color: #1ab394;
    }





}

section.director {
  padding-bottom: 5em;
  h2 {
    display: block;
    text-align: center;
    margin-bottom: 2em;
  }
  .descr_head {
    padding-bottom: 3em;
    background: url(../images/wave.png);
    background-size: 10.5em;
    background-repeat: no-repeat;
    background-position: 0% 90%;
    font-size: 1.3em;
  }
.office_img {
  border: .3em solid #e73d48;
  border-radius: .2em;
}
  a {
    text-decoration: underline;
    font-size: 1.2em;
    color: #1ab394;
  }
  .article_wrap {
    background: #eceeef;
    padding: 1em;
  }
  .hotline_img {
    width: 30%
  }
  .link {
    display: block;

  }
}

section.form {
  background: url(../images/new/bg_footer.jpg);
  padding: 0 0 3em;
  position: relative;
  z-index: 1;
  color: #fff;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: left bottom;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.45);
    z-index: -1;
  }
  h2 {
    margin: 2em auto 3em;
    text-align: center;
    color: #fff;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -2.5em;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
      width: 5.6em;
      height: 2em;
      background: url(../images/wave_green.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .form_wrap {
    float: right;
    width: 40%;
    text-align: center;
    form {
      width: 80%;
      margin: 2.2em auto 0;
    }
    input {
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 1.0769em 0 2.5625em;
      height: 3.7692em;
      border: none;
      -moz-border-radius: 0.3846em;
      border-radius: 0.3846em;
      color: #5a5a5a;
      font-size: 0.8125em;

    }


    input[type="file"] {
      width: .01em
      height: .01em
      opacity: 0
      overflow: hidden
      z-index: -1
      position: relative
      font-weight: lighter;
      top: 5em;
    }
    input.name {
      margin-bottom: 1em;
    }
    button.cta {
      width: 100%
    }
    .file_label {
      width: 100%;
      height: 3.0625em;
      border-radius: 0.3125em;
      border: 1px solid;
      margin: 1em 0;
      cursor: pointer;
      transition: color, background .3s;
      span {
        display: inline-block;
        line-height: 3.0625em;
      }
      &:hover {
        background: #fff;
        color: #1ab394;
        border: none;
      }
    }
  }
    .social {
      margin-top: 2em;
      text-align: right;
      margin-right: 2.1em;
    }

    .unit {
      display: inline-block;
      margin: 0 1em;
      a {
        font-size: 3em;
        color: #fff;

      }
    }
.link {
  position: absolute;
  left: 52%;
  transform: translate(-50%);
  bottom: 3.3em;
  color: #1ab394;
  display: inline-block;
  padding: 3em 1em 0;
  background: url(../images/wave.png);
  background-size: 11em;
  background-repeat: no-repeat;
  background-position: 70% 30%;
  z-index: 3;
  text-align: right;
a {
    color: #fff;
    font-size: 1.4em;
    line-height: initial;
}
}
.region {
  width: 80%;
  margin: auto;
  font-size: 1.1em;
}


}


button.slick-prev.slick-arrow {
  position: absolute;
  /* bottom: 3.89em; */
  z-index: 5;
  width: 4em;
  height: 4em;
  color: transparent;
  background: transparent;
  border: none;
  left: -5em;
  top: 50%;
  transform: translateY(-50%);
  border-radius: .4em;
  &:before {
    content: "\f101";
    font-family: 'Flaticon';
    font-size: 3em;
    color: #fff;
    position: absolute;
    left: 0.1em;
    top: -0.05em;
  }
}
button.slick-next.slick-arrow {
  position: absolute;
  /* bottom: 3.89em; */
  z-index: 5;
  /* left: 19.45em; */
  width: 4em;
  height: 4em;
  color: transparent;
  background: transparent;
  border: none;
  right: -5em;
  top: 50%;
  transform: translateY(-50%);
  border-radius: .4em;
  &:before {
    content: "\f100";
    font-family: 'Flaticon';
    font-size: 3em;
    color: #fff;
    position: absolute;
    left: 0.2em;
    top: -0.05em;
  }
}
.slider:focus {
  outline: none;
}

#placeholder_mobile {
  position: fixed;
  width:100%
  top:  0
  bottom: 0;
  z-index: 10000;
  background: url(../images/bg_main.jpg) center / cover no-repeat;
  h3 {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }
}

.wrap_fancybox {
  display: none
  //background: url(../images/bg_achievements.jpg);
  //background-size: 50em;
  //background-position: center;
  .text {
    margin-top: 3em
  }
    .photo img {
      max-width: 19em;
      border-radius: 0.4em;
    }
    .text_wrap {
      padding-top: 6em;
      position: relative;
      background: url(../images/wave_green.png);
      background-size: 14em;
      background-repeat: no-repeat;
      background-position: 50% 8%;
    }
    .bold {
      font-weight: bold
    }
    h4 {
      margin-bottom: 2em;
      position: relative;
      display: inline-block;
      font-weight: 400;
      &:after {
        content: '';
        position: absolute;
        bottom: -0.2em;
        height: 1px;
        width: 100%;
        background: #1bb394;
        left: 0;
      }
    }

}




//@media screen and (max-width: 768px) {
//  header {
//    height:auto;
//    font-size: 1.2em;
//  }
//header .heading, header .form_wrap {
//  margin-top: 14em;
//}
//header .back_form {
//  top: -1em;
//  width: 75%;
//}
//section.form .back_form {
//  width: 25em;
//  top: -1em;
//}
//
//
//
//}

//animation
//.stopAnimation {
//  animation: none !important;
//  opacity: 0;
//}
//.animated {
//  -webkit-animation-duration: 1s;
//  animation-duration: 1s;
//  -webkit-animation-fill-mode: both;
//  animation-fill-mode: both;
//}






@import 'flaticon.styl';

@media screen and (max-width: 560px) {
  .container-fluid {
    max-width: 100%
  }
  header {
    font-size: 1.2em;
    padding: 1em;
    height: auto;
    min-height: 100vh;
    .logo {
      width: 28em
    }
    .phone {
      font-size: 4em
    }
  }
header .form_wrap input {
  height: 3.8em;
  font-size: 1.2em
}
header .form_wrap label {
  font-size: 1.4em;
  margin: .6em 0;
  height: 3.2em
}
header .form_wrap button.cta {
  padding: 0
  height: 3.2em
}
header h1 {
  font-size: 4em
}
header .heading p {
  font-size: 2em
}

header .heading_wrap {
  width: 100%;
  height: auto;
  transform: none;
  left: 0;
  top: 0;
  font-size: 1.2em;
  position: relative;
  margin-bottom: 10em
}
header .heading, header .form_wrap {
  margin-top: 6em;
  width: 100%;
  left: 0;
  transform: none;
  /* text-align: center; */
  font-size: 1.4em;

}
header .form_wrap {
  margin-top: 2em
}
header h3 {
  font-size: 3em
}
.description {
  font-size: 2em;
}
.description .cloud {
  max-width:100%;
}
.description h2 {
  font-size: 2.6em
}

section.advantages .item img:before {
  display: none
}
section.advantages .third, section.advantages .half {
  width: 100%
}
  section.advantages .half:first-child {
    padding-left: 0
  }
section.advantages .half:last-child {
  padding-right: 0
}
section.advantages h2 {
  font-size: 5.6em;
  margin-top: 1em;
}
section.advantages .item img {
  width: 25em
}
section.advantages .item p {
  font-size: 3em;
  margin-bottom: 3em;
}
section.advantages .item_wrap {
  margin-top: 0
}
section.achievements h2 {
  font-size: 5.6em
}

section.achievements .wrapper {
  font-size: 3em
}
section.achievements .cta_wrap {
  margin-top: 0
  margin-bottom: 2em
}

section.story h2 {
  font-size: 5.6em
  margin-bottom: 2em
}
section.story .wrapper .photo {
  width: 100%
}
section.story .wrapper .photo .img-wrap {
  margin: auto
}
section.story .wrapper .text {
  width: 100%;
  font-size: 3em;
}
button.slick-prev.slick-arrow, button.slick-next.slick-arrow {
  top: 35em
}
section.story h3, section.story h4 {
  text-align: center
}
section.story h4:after {
  left: 50%
}
section.story .more {
  font-size: 1.8em
}
section.story .wrapper .text p {
  padding: 2em 0 0
  font-size: 1.4em
}
section.story .videowrap {
  width: 100%;
  font-size: 2em;
}
section.story .video_head {
  margin-top: 1em
}

section.owner .anetta {
  width: 50%
}

.region {
  font-size: 1.4em
}

.wrap_fancybox  {
  .photo {
    display: none;
  }
  h3, h4 {
    font-size: 4em
  }
  p {
    font-size: 3em;
    padding: 0;
  }
    h5 {
      font-size: 1em
    }
}
.wrap_fancybox .text_wrap {
  padding-top: 0
}
section.owner h2 {
  font-size: 5.6em
}
section.owner .heading {
  font-size: 4em
}





section.owner .profi {
  width: 25%
}
section.owner .img_wrap {
  margin-bottom: 5em
}
section.director .article_wrap {
  margin-bottom: 5em
}
section.director .link {
  display: block;
  text-align: center;
  font-size: 4em;
}
section.owner .profi + a {
  font-size: 4em;
}
section.owner .flex, section.director .flex {
  display: flex;
  flex-direction: column-reverse;
}
section.owner .descr, section.director .descr  {
  font-size: 3em
  margin-bottom: 2em
}

section.achievements .cta_wrap {
  font-size: 4em
}
section.director h2 {
  font-size: 5.6em
  margin-bottom: 2em
}
section.director .article_wrap {
  font-size: 3em
  margin-bottom: 2em;
}


section.form .form_wrap {
  float: none;
  width: 80%;
  text-align: center;
  margin: auto;
  font-size: 2em
}
section.form .form_wrap input, section.form .form_wrap label {
  font-size: 1.2em
}
section.form h2 {
  font-size: 5.6em
}
section.form .unit a {
  font-size: 8em
}
section.form .social {
  margin-right: 0
}
section.form .region {
  font-size: 1.4em
}


section.form .social {
  text-align: center
}
section.form .link {
  margin: 5em auto 1em;
  display: block;
  position: relative;
  left: 0;
  transform: none;
  text-align: center;
  font-size: 2.1em;
  background-position: 50% 20%;
}

}
