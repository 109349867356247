@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon/Flaticon.eot");
  src: url("../fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/flaticon/Flaticon.woff") format("woff"),
    url("../fonts/flaticon/Flaticon.ttf") format("truetype"),
    url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}


.flaticon-angle-arrow-pointing-to-right:before { content: "\f100"; }
.flaticon-angle-pointing-to-left:before { content: "\f101"; }
.flaticon-businessman:before { content: "\f102"; }
.flaticon-cash:before { content: "\f103"; }
.flaticon-change:before { content: "\f104"; }
.flaticon-down-chevron:before { content: "\f105"; }
.flaticon-facebook:before { content: "\f106"; }
.flaticon-facebook-1:before { content: "\f107"; }
.flaticon-instagram:before { content: "\f108"; }
.flaticon-livejournal:before { content: "\f109"; }
.flaticon-male-graduated-student:before { content: "\f10a"; }
.flaticon-mortarboard:before { content: "\f10b"; }
.flaticon-play-button:before { content: "\f10c"; }
.flaticon-stats:before { content: "\f10d"; }
.flaticon-travel:before { content: "\f10e"; }
.flaticon-twitter:before { content: "\f10f"; }
.flaticon-up-arrow:before { content: "\f110"; }
.flaticon-vk:before { content: "\f111"; }
.flaticon-wallet:before { content: "\f112"; }
